import { appointment } from './appointment';

export class appointmentCalendar {
    public name!:string;
    public start!:string;
    public end !: string;
    public color!:string;
    public classtextcolor!:string;
    public icono!:string;
    public metadata!:appointment;  
 } 
